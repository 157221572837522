<template>
  <span>
    <span
      class="filter d-inline-flex align-items-center fs-14 me-16"
      v-for="filter of activeFilters"
      :key="filter.key"
    >
      <strong class="text-primary me-8">{{ filter.label }}</strong>
      <span class="me-8">{{ filter.value }}</span>
      <button
        class="btn-plain d-inline-flex align-items-center"
        v-tooltip="'Hapus'"
        @click="$emit('remove', filter)"
      >
        <XIcon />
      </button>
    </span>
  </span>
</template>

<script>
import XIcon from 'bootstrap-icons/icons/x.svg';

export default {
  components: { XIcon },

  props: ['activeFilters'],
};
</script>

<style lang="scss" scoped>
.filter {
  background: tint-color($primary, 90%);
  padding: rem(4) rem(12);
  border-radius: rem(16);

  button {
    &:hover,
    &:focus {
      background: tint-color($primary, 80%);
    }
  }
}
</style>
