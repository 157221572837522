<script>
import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';

export default {
  data() {
    return { input: this.parseInput() };
  },

  computed: {
    activeFilters() {
      const labels = { search: 'Cari' };
      const filters = { search: this.input.search, ...this.input.filter };

      return Object.entries(filters)
        .filter(
          ([, value]) => value !== '' && value !== null && value !== undefined
        )
        .map(([key, value]) => {
          const method = camelCase(`getActiveFilter ${key}`);

          if (this[method]) {
            return this[method](value);
          }

          return {
            label: labels[key],
            key,
            value,
          };
        })
        .filter(Boolean);
    },
  },

  methods: {
    parseInput() {
      return Object.entries(this.$route.query).reduce(
        (result, [key, value]) => {
          switch (key) {
            case 'filter':
              result[key] = this.parseFilter(value);
              break;
            default:
              result[key] = value;
              break;
          }

          return result;
        },
        { filter: {} }
      );
    },

    parseFilter(filter) {
      return Object.entries(filter).reduce((result, [key, value]) => {
        const method = camelCase(`parseFilter ${key}`);

        if (this[method]) {
          this[method](result, value);
        } else {
          result[key] = value;
        }

        return result;
      }, {});
    },

    prepareInput() {
      return Object.entries(this.input).reduce((result, [key, value]) => {
        switch (key) {
          case 'filter':
            result[key] = this.prepareFilter(value);
            break;
          default:
            result[key] = value;
            break;
        }

        return result;
      }, {});
    },

    prepareFilter(filter) {
      return Object.entries(filter).reduce((result, [key, value]) => {
        const method = camelCase(`prepareFilter ${key}`);

        if (this[method]) {
          this[method](result, value);
        } else if (value !== null && value !== undefined) {
          result[key] = value;
        }

        return result;
      }, {});
    },

    debouncedPush: debounce(function () {
      this.$router.replace({ query: this.prepareInput() });
    }, 500),

    removeFilter(filter) {
      if (filter.key === 'search') {
        this.$delete(this.input, filter.key);
      } else {
        this.$delete(this.input.filter, filter.key);
      }
    },

    /**
     * Focus to first filter field.
     */
    async focusToField() {
      await this.$nextTick();

      const input = document.querySelector('.v-popper__popper input');
      input && input.focus();
    },
  },

  watch: {
    input: {
      handler() {
        this.debouncedPush();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  min-height: rem(29);
}

.popper-content {
  min-width: rem(270);
}
</style>
