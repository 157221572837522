<template>
  <VDropdown class="d-inline">
    <button class="btn-plain" v-tooltip="'Tindakan'">
      <ThreeDotsVerticalIcon />
    </button>

    <template #popper>
      <div class="p-8">
        <button
          class="btn d-flex align-items-center"
          :key="action.text"
          v-for="action of actions(row)"
          v-bind="action.attrs"
          v-close-popper
          @click="action.onClick({ column, row })"
        >
          <component class="me-8" :is="action.icon" />
          {{ action.text }}
        </button>
      </div>
    </template>
  </VDropdown>
</template>

<script>
import { VClosePopper } from 'v-tooltip';

import VDropdown from '../v-tooltip/VDropdown.vue';
import ThreeDotsVerticalIcon from 'bootstrap-icons/icons/three-dots-vertical.svg';

export default {
  components: { VDropdown, ThreeDotsVerticalIcon },

  props: ['actions', 'column', 'row'],

  directives: { 'close-popper': VClosePopper },
};
</script>
